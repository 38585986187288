import device from '~/mixins/device.js'
import modalSignup from '~/mixins/modalSignup.js'
import stripe from '~/mixins/stripe.js'

export default {
  mixins: [device, modalSignup, stripe],

  description: {
    type: String,
  },

  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    title: {
      type: String,
    },

    description: {
      type: String,
    },

    button: {
      type: Object,
    },

    image: {
      type: Object,
    },
  },
}
